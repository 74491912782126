<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-tree-table
          title="공정 목록"
          parentProperty="upProcessCd"
          customID="processCd"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 검색 -->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <q-form ref="editForm">
          <!-- 상세 -->
          <c-card title="LBLDETAIL" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 등록 -->
                <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="addData" />
                <!-- 저장 -->
                <c-btn
                  v-if="inputEditable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <!-- 공정명 -->
                <c-text
                  :editable="inputEditable"
                  :required="true"
                  label="LBL0003230"
                  name="processName"
                  v-model="data.processName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <!-- <c-location
                  type="edit"
                  :editable="inputEditable"
                  label="상위 장소"
                  name="upMdmLocationId"
                  v-model="location.upMdmLocationId">
                >
                </c-location> -->
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!-- 공정설명 -->
                <c-textarea
                  :counter="true"
                  :editable="inputEditable"
                  label="LBL0003238"
                  name="processDesc"
                  :rows="2"
                  v-model="data.processDesc">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-plant
                  :required="true"
                  :editable="inputEditable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <!-- 사용여부 -->
                <c-checkbox
                  :editable="inputEditable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <!-- 정렬순서 -->
                <c-text
                  :editable="inputEditable"
                  type="number"
                  label="정렬순서"
                  name="sortOrder"
                  v-model="data.sortOrder">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-manage',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            // 공정명
            label: 'LBL0003230',
            align: 'left',
            sortable: true,
          },
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width:120px'
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '정렬순서',
            align: 'center',
            sortable: true,
            style: 'width:80px'
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
        ],
        data: [],
      },
      data: {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        sortOrder: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'Y',
      },
      useItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      isModify: false,
      editable: true,
      isSave: false,
      listUrl: '',
      detailUrl: '',
      saveUrl: '',
      saveType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    inputEditable() {
      return this.isModify && this.editable;
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.process.list.url;
      this.detailUrl = selectConfig.mdm.process.get.url;
      this.saveUrl = transactionConfig.mdm.process.insert.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick(row) {
      // 상세조회
      this.$http.url = this.$format(this.detailUrl, row.processCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.data, _result.data);
        this.isModify = true;
      },);
    },
    addData() {
      Object.assign(this.$data.data, this.$options.data().data);
      this.isModify = true;
      this.data.plantCd = this.searchParam.plantCd;
    },
    saveData() {
      if (this.data.processCd) {
        this.saveUrl = transactionConfig.mdm.process.insert.url;
        this.saveType = 'PUT'
      } else {
        this.saveUrl = transactionConfig.mdm.process.insert.url;
        this.saveType = 'POST'
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId 
              this.data.chgUserId = this.$store.getters.user.userId 

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({ processCd: result.data })
    },
  }
};
</script>
